@font-face {
  font-family: "sdLi"; // Apple SD 산돌고딕 Neo Light
  src: url("./assets/font/AppleSDGothicNeoL.ttf") format("truetype");
}

@font-face {
  font-family: "sdMe"; // Apple SD 산돌고딕 Neo Medium
  src: url("./assets/font/AppleSDGothicNeoM.ttf") format("truetype");
}

@font-face {
  font-family: "sdEB"; // Apple SD 산돌고딕 Neo Extra Bold
  src: url("./assets/font/AppleSDGothicNeoEB.ttf") format("truetype");
}

@font-face {
  font-family: "sdBo"; // Apple SD 산돌고딕 Neo Bold
  src: url("./assets/font/AppleSDGothicNeoB.ttf") format("truetype");
}

@font-face {
  font-family: "sdSb"; // Apple SD 산돌고딕 Neo Semi Bold
  src: url("./assets/font/AppleSDGothicNeoSB.ttf") format("truetype");
}

@font-face {
  font-family: "tmoneyBo"; // 티머니 둥근바람 Bold
  src: url("./assets/font/TmoneyRoundWindExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "tmoneyRe"; // 티머니 둥근바람 regular
  src: url("./assets/font/TmoneyRoundWindRegular.ttf") format("truetype");
}

@font-face {
  font-family: "sfPro"; // SF Pro
  src: url("./assets/font/SF-Pro.ttf") format("truetype");
}

@font-face {
  font-family: "sfProL"; // SF Pro Light
  src: url("./assets/font/SF-Pro-Light.otf") format("truetype");
}

@font-face {
  font-family: "sfProUL"; // SF Pro Ultarlight
  src: url("./assets/font/SF-Pro-Ultralight.otf") format("truetype");
}

@font-face {
  font-family: "sfProT"; // SF Pro Thin
  src: url("./assets/font/SF-Pro-Thin.otf") format("truetype");
}

@font-face {
  font-family: "sfProBo"; // SF Pro Bold
  src: url("./assets/font/SF-Pro-Bold.otf") format("truetype");
}

@font-face {
  font-family: "sfProSb"; // SF Pro Semi Bold
  src: url("./assets/font/SF-Pro-Semibold.otf") format("truetype");
}
@primary-color: #c32020;