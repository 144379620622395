.overlay {
  display: block;
  position: fixed;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.3);

  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
